// General
import "./meet-your-date.scss";
// Assets
import MeetYourDateImage from "../../../assets/others/stockphotos/meet-your-date.png";

const MeetYourDate = () => {
  return (
    <div id="landing-meet-your-date-subcomponent">
      <div className="meet-your-date-description-container">
        <div className="mobile-center-container">
          <div className="header">Meet Your Sweet Date</div>

          <div className="text">
            Chat, meet, and connect with influential & attractive individuals on
            your terms & conditions. Be a part of our 4 million community
            members that have successfully found their match & build modern
            relationships.
          </div>
          <div className="text">
            Sign up now to find your sweet companion near you.
          </div>
        </div>
      </div>

      <div className="meet-your-date-image-container">
        <img className="meet-your-date" src={MeetYourDateImage} />
      </div>
    </div>
  );
};

export default MeetYourDate;
