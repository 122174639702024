import { configureStore } from "@reduxjs/toolkit";
import { authApi, comApi, spiApi } from "../services/data.service";
import signupReducer from "./store/signup";
import redirectReducer from "./store/redirect";
import accountReducer from "./store/account";

export default configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [comApi.reducerPath]: comApi.reducer,
    [spiApi.reducerPath]: spiApi.reducer,
    signup: signupReducer,
    redirect: redirectReducer,
    account: accountReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      comApi.middleware,
      spiApi.middleware
    ),
});
