// General
import "./redirect.scss";
// Services
import { useGetTokenQuery } from "../../services/data.service";
// Environment
import environment from "../../environment/environment";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateToken } from "../../redux/store/redirect";

const Redirect = () => {
  // API variables
  const {
    data: getTokenData,
    error: getTokenErrorData,
    isLoading: getTokenLoading,
    isSuccess: getTokenSuccess,
    isError: getTokenError,
  } = useGetTokenQuery();

  // Redux variables
  const { token } = useSelector((state) => state.redirect);
  const dispatch = useDispatch();

  // API Response
  if (getTokenSuccess) {
    dispatch(updateToken(getTokenData.data.token));

    let redirectUrl = `${environment.redirectUrl}${token}`;
    window.location.replace(redirectUrl);
  }

  return (
    <div id="redirect-page">
      <div className="center-container">
        <div className="header">Thank You</div>
        <div className="text">Please wait while we setup your account</div>
      </div>
    </div>
  );
};

export default Redirect;
