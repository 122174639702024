// General
import "./about-us.scss";
import { Link } from "react-router-dom";
// Static Data
import route from "../../const/route";
// Components
import Footer from "../shared/footer/footer";

const AboutUs = () => {
  return (
    <div id="about-us-page">
      <div className="top-container">
        <div className="max-width-container">
          <div className="header">About Us</div>

          <div className="text">At Candybook, our goal is to provide you with the premier dating experience. Your satisfaction and discretion will always remain our top priority.</div>
          <div className="text">Founded in 2017, we are Asia's largest social networking platform. With over 4 million members worldwide, you can now experience your ideal mutually beneficial relationship in Malaysia with the tap of a button.</div>
          <div className="text">Get started by choosing your role. Add a photo and show other members why you're their ideal partner.</div>
        
          <Link className="button-container" to={route.join.path}>
            <div className="button">JOIN FREE TODAY</div>
          </Link>
        </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default AboutUs;
