// General
import "./features.scss";
// Static Data
import landing from "../../../const/landing";
// Carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const Features = () => {
  const featuresData = landing.features;

  return (
    <div id="landing-features-subcomponent">
      <div className="feature-list-container">
        <div className="desktop-view">
          {featuresData.map((feature, index) => (
            <div className="feature-container" key={index}>
              <div className="feature-image-container">
                <img className="feature-image" src={feature.image} />
              </div>

              <div className="feature-header">{feature.header}</div>

              <div className="feature-description">{feature.description}</div>
            </div>
          ))}
        </div>

        <div className="mobile-view">
          <Carousel showArrows={true}>
            {featuresData.map((feature, index) => (
              <div className="feature-container" key={index}>
                <div className="feature-image-container">
                  <img className="feature-image" src={feature.image} />
                </div>

                <div className="feature-header">{feature.header}</div>

                <div className="feature-description">{feature.description}</div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Features;
