// General
import "./join.scss";
import { useNavigate } from "react-router-dom";
// Static Data
import route from "../../const/route";
// Enums
import user from "../../enums/user";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateGender, updateLookingFor } from "../../redux/store/signup";
// Material UI
import Button from "@mui/material/Button";

function Join() {
  // Redux variables
  const { gender, lookingFor } = useSelector((state) => state.signup);
  const dispatch = useDispatch();

  // Utility variables
  const navigate = useNavigate();

  // Event Handlers | Button
  const onNextPage = () => {
    navigate(route.accountSignup.path);
  };

  // Event Handlers | MUI
  const onGenderSelect = (e) => {
    dispatch(updateGender(e));
    dispatch(updateLookingFor(""));
  };
  const onLookingForSelect = (e) => {
    dispatch(updateLookingFor(e));
  };

  // Utility Functions
  const isFormValid = () => {
    let valid = false;
    if (gender !== "" && lookingFor !== "") {
      valid = true;
    }

    return valid;
  };

  return (
    <div id="join-page" className="join-container">
      <div className="max-width-container">
        <div className="first-container">
          <div className="header">I am a...</div>
          <div className="body-container">
            <div
              className={`body-label ${gender === "man" ? "selected" : ""}`}
              onClick={() => onGenderSelect(user.gender.MAN)}
            >
              Man
            </div>
            <div
              className={`body-label ${gender === "woman" ? "selected" : ""}`}
              onClick={() => onGenderSelect(user.gender.WOMAN)}
            >
              Woman
            </div>
          </div>
        </div>

        {gender !== "" && (
          <div className="second-container">
            <div className="header">I'm looking for</div>
            {gender === "man" && (
              <div className="body-container">
                <div
                  className={`body-label ${
                    lookingFor === "sugarbaby" ? "selected" : ""
                  }`}
                  onClick={() => onLookingForSelect(user.lookingFor.SUGARBABY)}
                >
                  Sugar Baby
                </div>
                <div
                  className={`body-label ${
                    lookingFor === "sugardaddy" ? "selected" : ""
                  }`}
                  onClick={() => onLookingForSelect(user.lookingFor.SUGARMOMMY)}
                >
                  Sugar Mommy
                </div>
              </div>
            )}
            {gender === "woman" && (
              <div className="body-container">
                <div
                  className={`body-label ${
                    lookingFor === "sugardaddy" ? "selected" : ""
                  }`}
                  onClick={() => onLookingForSelect(user.lookingFor.SUGARDADDY)}
                >
                  Sugar Daddy
                </div>
                <div
                  className={`body-label ${
                    lookingFor === "sugarbaby" ? "selected" : ""
                  }`}
                  onClick={() =>
                    onLookingForSelect(user.lookingFor.MALE_SUGARBABY)
                  }
                >
                  Male Sugar Baby
                </div>
              </div>
            )}
          </div>
        )}

        <Button
          className={`continue-button ${
            isFormValid() ? "" : "disabled-button"
          }`}
          variant="text"
          onClick={onNextPage}
          disabled={!isFormValid()}
        >
          Continue
        </Button>
      </div>
    </div>
  );
}

export default Join;
