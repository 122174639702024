// General
import "./footer.scss";

const Footer = () => {
  return (
    <div id="footer">
      <div className="text">Candybook 2022. All Rights Reserved</div>
    </div>
  );
};

export default Footer;
