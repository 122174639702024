// General
import "./info-signup.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Assets
import NoPhoto from "../../../assets/icons/shared/nophoto_neutral.svg";
// Services
import { updateName } from "../../../redux/store/signup";
import { useProfileUpdateMutation } from "../../../services/data.service";
// Static Data
import route from "../../../const/route";
// Redux
import { useSelector, useDispatch } from "react-redux";
// Material UI
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const InfoSignup = () => {
  // General variables
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [nameFocus, setNameFocus] = useState(false);
  const [nameValid, setNameValid] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [orientationData, setOrientationData] = useState(-1);
  const [imageData, setImageData] = useState({
    name: "",
    image: false,
    url: "",
    file: "",
  });

  // API variables
  const [
    profileUpdate,
    {
      data: profileUpdateData,
      error: profileUpdateErrorData,
      isLoading: profileUpdateLoading,
      isSuccess: profileUpdateSuccess,
      isError: profileUpdateError,
    },
  ] = useProfileUpdateMutation();

  // Redux variables
  const { name } = useSelector((state) => state.signup);
  const dispatch = useDispatch();

  // Utility variables
  const navigate = useNavigate();

  // API Response
  useEffect(() => {
    if (profileUpdateSuccess) {
      navigate(route.redirect.path);
    }
  }, [profileUpdateSuccess]);

  // Event Handlers
  const onRedirect = () => {
    const obj = {
      new_username: name,
    };

    profileUpdate(obj);
  };
  const onFilePicked = (e) => {
    const file = e.target.files[0];

    if (file !== undefined) {
      // Detect Camera Orientation Data
      getImageOrientation(
        file,
        function (orientation) {
          saveOrientationData(orientation);
        }.bind(this)
      );

      const fr = new FileReader();
      fr.onload = (e) => {
        setImageData((prevState) => ({
          ...prevState,
          image: e.target.result,
        }));
      };
      fr.readAsDataURL(file);
      fr.addEventListener("load", () => {
        setImageData((prevState) => ({
          ...prevState,
          file: file,
        }));
      });
    }
  };

  // Event Handlers | MUI
  const onNameChange = (e) => {
    dispatch(updateName(e.target.value));
  };
  const onNameFocus = (e) => {
    setNameFocus(true);
  };

  // Utility Functions
  const isFormValid = () => {
    let valid = false;
    if (name !== "") {
      valid = true;
    }

    return valid;
  };
  const nameValidation = () => {
    let valid = true;
    let error = false;

    if (!nameFocus) {
      valid = false;
      error = false;
    } else if (nameFocus && name === "") {
      setNameErrorMessage("Please enter your name");
      valid = false;
      error = true;
    } else {
      setNameErrorMessage("");
      valid = true;
      error = false;
    }

    setNameValid(valid);
    setNameError(error);
  };
  const saveOrientationData = (orientation_data) => {
    setOrientationData(orientation_data);
  };
  const getImageOrientation = (file, callback) => {
    var reader = new FileReader();

    reader.onload = function (event) {
      var view = new DataView(event.target.result);

      if (view.getUint16(0, false) !== 0xffd8) return callback(-2);

      var length = view.byteLength,
        offset = 2;

      while (offset < length) {
        var marker = view.getUint16(offset, false);
        offset += 2;

        if (marker === 0xffe1) {
          if (view.getUint32((offset += 2), false) !== 0x45786966) {
            return callback(-1);
          }
          var little = view.getUint16((offset += 6), false) === 0x4949;
          offset += view.getUint32(offset + 4, little);
          var tags = view.getUint16(offset, little);
          offset += 2;

          for (var i = 0; i < tags; i++)
            if (view.getUint16(offset + i * 12, little) === 0x0112)
              return callback(view.getUint16(offset + i * 12 + 8, little));
        } else if ((marker & 0xff00) !== 0xff00) break;
        else offset += view.getUint16(offset, false);
      }
      return callback(-1);
    };

    reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
  };

  // Check for name validation
  useEffect(() => {
    nameValidation();
  }, [name, nameFocus, nameErrorMessage]);

  return (
    <div id="info-signup-page">
      <div className="max-width-container">
        <div className="started-label">Let's get started!</div>

        <Button className="profile-img-button" disableRipple component="label">
          <input hidden accept="image/*" type="file" onChange={onFilePicked} />
          {imageData.image ? (
            <img className="profile-img" src={imageData.image} alt="default" />
          ) : (
            <img className="profile-img" src={NoPhoto} />
          )}
        </Button>

        <div className="description">Upload your photo to continue</div>

        <div className="name-form-field-container">
          <TextField
            className="name-form-field"
            sx={{
              "& .MuiInput-underline:after": { borderBottomColor: "#710D0D" },
              "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
            }}
            required
            value={name}
            error={nameError}
            onChange={onNameChange}
            label="Display Name"
            helperText={nameErrorMessage}
            variant="standard"
            onFocus={onNameFocus}
          />
        </div>

        {/* <div className="dob-form-field-container">
          <TextField
            className="dob-form-field"
            placeholder="Date of Birth"
            variant="standard"
          />
        </div>

        <div className="location-form-field-container">
          <TextField
            className="location-form-field"
            placeholder="Your location"
            variant="standard"
          />
        </div> */}

        <Button
          className={`continue-button ${
            isFormValid() ? "" : "disabled-button"
          }`}
          variant="text"
          onClick={onRedirect}
          disabled={!isFormValid()}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default InfoSignup;
