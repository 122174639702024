// General
import "./hero.scss";
import { Link } from "react-router-dom";
// Assets
import HeroImage from "../../../assets/others/stockphotos/hero.png";
import BrandLogoUpper from "../../../assets/logo/candybook-uppercase.png"
// Static Data
import route from "../../../const/route";

const Hero = () => {
  return (
    <div id="landing-hero-subcomponent">
      <div className="hero-image-container">
        <img className="hero-image" src={HeroImage} />
      </div>

      <div className="hero-description-container">
        <div className="mobile-center-container">
          <div className="text welcome">Welcome to</div>
          <div className="brand-logo-container">
            <img className="brand-logo" src={BrandLogoUpper} />
          </div>

          <div className="text premier-1">Premier dating platform,</div>
          <div className="text premier-2">made for the elites.</div>

          <div className="text journey">Start your modern relationship journey</div>

          <Link className="join-button-container" to={route.join.path}>
            <div className="join-button">Join FREE Now</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
