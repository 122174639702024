// General
import "./join-now.scss";
import { Link } from "react-router-dom";
// Assets
import JoinNowImage from "../../../assets/others/stockphotos/join-now.png";
// Static Data
import route from "../../../const/route";

const JoinNow = () => {
  return (
    <div id="landing-join-now-subcomponent">
      <div className="gradient-background">
        <div className="image-container">
          <img className="image" src={JoinNowImage} />
        </div>

        <div className="join-now-description-container">
          <div className="header">Start Your Modern Relationships Today</div>

          <Link className="join-now-button-container" to={route.join.path}>
            <div className="join-now-button">Join FREE Now</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default JoinNow;
