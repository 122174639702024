// General
import "./description-signup.scss";
import { Link } from "react-router-dom";
// Static Data
import route from "../../../const/route";
// Material UI
import TextField from "@mui/material/TextField";

const DescriptionSignup = () => {
  return (
    <div id="description-signup-page">
      <div className="max-width-container">
        <div className="steps-label">Step 1 / 3</div>

        <div className="step-description-label">Tell us about yourself</div>

        <div className="about-me-form-field-container">
          <TextField
            className="about-me-form-field"
            label="About Me"
            multiline
            maxRows={5}
            rows={5}
            variant="standard"
          />
        </div>

        <div className="looking-for-form-field-container">
          <TextField
            className="looking-for-form-field"
            label="Looking For"
            multiline
            maxRows={5}
            rows={5}
            variant="standard"
          />
        </div>

        <div className="button-container">
          <div className="next-button-container">
            <Link
              className="next-button inactive-button"
              to={route.lifestyleSignup.path}
            >
              Next
            </Link>
          </div>

          <div className="skip-button-container">
            <Link className="skip-button" to={route.lifestyleSignup.path}>
              Skip For Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DescriptionSignup;
