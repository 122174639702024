const route = {
  landing: { name: "Landing", path: "/" },
  aboutUs: { name: "About Us", path: "/about-us" },
  terms: { name: "Terms", path: "/terms" },
  join: { name: "Join", path: "/join" },
  accountSignup: { name: "Account Signup", path: "/account-signup" },
  infoSignup: { name: "Info Signup", path: "/info-signup" },
  descriptionSignup: { name: "Description Signup", path: "/description-signup" },
  lifestyleSignup: { name: "Lifestyle Signup", path: "/lifestyle-signup" },
  detailSignup: { name: "Detail Signup", path: "/detail-signup" },
  emailVerification: { name: "Email Verification", path: "/email-verification" },
  redirect: { name: "Redirect", path: "/redirect" },
};

export default route;
