// General
import "./App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// Static Data
import route from "./const/route";
// Components
import NavBar from "./components/shared/navbar/navbar";
import Landing from "./components/landing/landing";
import AboutUs from "./components/about-us/about-us";
import Terms from "./components/terms/terms";
import Join from "./components/join/join";
import AccountSignup from "./components/signup/account-signup/account-signup";
import DescriptionSignup from "./components/signup/description-signup/description-signup";
import LifestyleSignup from "./components/signup/lifestyle-signup/lifestyle-signup";
import DetailSignup from "./components/signup/detail-signup/detail-signup";
import EmailVerification from "./components/signup/email-verification/email-verification";
import Redirect from "./components/redirect/redirect";
import InfoSignup from "./components/signup/info-signup/info-signup";
import ErrorPage from "./components/error-page/error-page";

function App() {
  return (
    <Router>
      <div id="App">
        <NavBar />

        <div className="body-container">
          <Routes>
            <Route exact path={route.landing.path} element={<Landing />} />
            <Route path={route.aboutUs.path} element={<AboutUs />} />
            <Route path={route.terms.path} element={<Terms />} />
            <Route path={route.join.path} element={<Join />} />
            <Route
              path={route.accountSignup.path}
              element={<AccountSignup />}
            />
            <Route
              path={route.descriptionSignup.path}
              element={<DescriptionSignup />}
            />
            <Route
              path={route.lifestyleSignup.path}
              element={<LifestyleSignup />}
            />
            <Route
              exact
              path={route.detailSignup.path}
              element={<DetailSignup />}
            />
            <Route
              exact
              path={route.infoSignup.path}
              element={<InfoSignup />}
            />
            <Route
              path={route.emailVerification.path}
              element={<EmailVerification />}
            />
            <Route path={route.redirect.path} element={<Redirect />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
