// General
import "./account-signup.scss";
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom";
// Services
import { useSignupMutation } from "../../../services/data.service";
import { emailRegex } from "../../../services/regex.service";
// Static Data
import route from "../../../const/route";
// Environment
import environment from "../../../environment/environment";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateEmail, updatePassword } from "../../../redux/store/signup";
import {
  updateApiToken,
  updateUserProfile,
} from "../../../redux/store/account";
// Material UI
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

const AccountSignup = () => {
  // General variables
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // API variables
  const [
    signup,
    {
      data: signupData,
      error: signupErrorData,
      isLoading: signupLoading,
      isSuccess: signupSuccess,
      isError: signupError,
    },
  ] = useSignupMutation();

  // Redux variables
  const { email, password } = useSelector((state) => state.signup);
  const dispatch = useDispatch();

  // Utility variables
  const navigate = useNavigate();

  // API Response
  useEffect(() => {
    if (signupSuccess) {
      dispatch(updateApiToken(signupData.data.token));
      dispatch(updateUserProfile(signupData.data.user));
      // navigate(route.infoSignup.path);
      navigate(route.redirect.path);
    }
  }, [signupSuccess]);

  // Catch for Signup API error
  useEffect(() => {
    if (signupError) {
      onOpenSnackbar();
    }
  }, [signupError]);

  // Event Handlers | Button
  const onSignup = () => {
    const obj = {
      action: "signup",
      auth_provider: "email_password",
      email: email,
      gender: "man",
      looking_for: "sugarbaby",
      password: password,
      reference_url: environment.referenceUrl,
      utm_data: "{}",
    };

    signup(obj);
  };

  // Event Handlers | MUI
  const onEmailChange = (e) => {
    dispatch(updateEmail(e.target.value));
  };
  const onEmailFocus = (e) => {
    setEmailFocus(true);
  };
  const onPasswordChange = (e) => {
    dispatch(updatePassword(e.target.value));
  };
  const onPasswordFocus = (e) => {
    setPasswordFocus(true);
  };
  const onOpenSnackbar = () => {
    setOpenSnackbar(true);
  };
  const onCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  // Utility Functions
  const isFormValid = () => {
    let valid = false;
    if (emailValid && passwordValid) {
      valid = true;
    }

    return valid;
  };
  const emailValidation = () => {
    let valid = true;
    let error = false;

    const regex = emailRegex;

    if (!emailFocus) {
      valid = false;
      error = false;
    } else if (emailFocus && email === "") {
      setEmailErrorMessage("Email cannot be empty");
      valid = false;
      error = true;
    } else if (!regex.test(email)) {
      setEmailErrorMessage("Email is not valid");
      valid = false;
      error = true;
    } else {
      setEmailErrorMessage("");
      valid = true;
      error = false;
    }

    setEmailValid(valid);
    setEmailError(error);
  };
  const passwordValidation = () => {
    let valid = true;
    let error = false;

    if (!passwordFocus) {
      valid = false;
      error = false;
    } else if (passwordFocus && password === "") {
      setPasswordErrorMessage("Password cannot be empty");
      valid = false;
      error = true;
    } else if (passwordFocus && password.length < 6) {
      setPasswordErrorMessage("Password must be at least 6 characters");
      valid = false;
      error = true;
    } else {
      setPasswordErrorMessage("");
      valid = true;
      error = false;
    }

    setPasswordValid(valid);
    setPasswordError(error);
  };

  // Check for email and password validation
  useEffect(() => {
    emailValidation();
    passwordValidation();
  }, [
    email,
    password,
    emailFocus,
    passwordFocus,
    emailErrorMessage,
    passwordErrorMessage,
  ]);

  return (
    <Box
      id="account-signup-page"
      component="form"
      noValidate
      autoComplete="off"
    >
      <div className="max-width-container">
        <div className="create-account-label">Create New Account</div>
        <div className="email-form-field-container">
          <TextField
            className="email-form-field"
            sx={{
              "& .MuiInput-underline:after": { borderBottomColor: "#710D0D" },
              "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
            }}
            required
            value={email}
            error={emailError}
            onChange={onEmailChange}
            label="Email Address"
            placeholder="Email Address"
            helperText={emailErrorMessage}
            variant="standard"
            onFocus={onEmailFocus}
          />
        </div>

        <div className="password-form-field-container">
          <TextField
            className="password-form-field"
            sx={{
              "& .MuiInput-underline:after": { borderBottomColor: "#710D0D" },
              "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
            }}
            required
            value={password}
            error={passwordError}
            onChange={onPasswordChange}
            label="Password"
            type="password"
            placeholder="Password"
            helperText={passwordErrorMessage}
            variant="standard"
            onFocus={onPasswordFocus}
          />
        </div>

        <Button
          className={`signup-button ${isFormValid() ? "" : "disabled-button"}`}
          variant="text"
          onClick={onSignup}
          disabled={!isFormValid()}
        >
          {signupLoading ? (
            <CircularProgress className="button-spinner" size={24} />
          ) : (
            "Sign Up"
          )}
        </Button>

        <div className="terms-and-condition">
          By continuing you agree to Candybook's{" "}
          <span className="terms-container">
            {/* Open dialog */}
            <Link className="terms" to="/terms">
              Terms
            </Link>
          </span>{" "}
          &{" "}
          <span className="privacy-policy">
            {/* Open dialog */}
            <Link className="privacy-policy" to="/privacy">
              Privacy Policy
            </Link>
          </span>
          . Promoting illegal commercial activities (such as prostitution) is
          prohibited. Users must be at least 18 years old.
        </div>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={onCloseSnackbar}
        message={signupErrorData?.data?.message}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        key={"bottom" + "center"}
      ></Snackbar>
    </Box>
  );
};

export default AccountSignup;
