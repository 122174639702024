// General
import "./landing.scss";
// Components
import Hero from "./hero/hero";
import MeetYourDate from "./meet-your-date/meet-your-date";
import Features from "./features/features";
import MultiDevice from "./multi-device/multi-device";
import JoinNow from "./join-now/join-now";
import Footer from "../shared/footer/footer";

const Landing = () => {
  return (
    <div id="landing-page">
      <Hero />
      
      <MeetYourDate />

      <Features />

      <MultiDevice />

      <JoinNow />

      <Footer />
    </div>
  );
};

export default Landing;
