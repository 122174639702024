// const environment = {
//   appVersion: "1.0.0",
//   env: "local",
//   production: false,
//   apiUrl: "https://dev-sb-api.sgrbk.com/",
//   authApi: "https://dev-sb-api.sgrbk.com/",
//   comApi: "https://tsbcom.sgrbk.com/",
//   spiApi: "https://dev-spi.sgrbk.com/",
//   referenceUrl: "https://m.sugarbook.club",
//   apiAccessToken: "Ezetj4whWp4XvhhkJ7CBtQWa2cEjUMyl",
//   redirectUrl:
//     "https://m.sugarbook.me/redirect_handler?url=/&from_cta=candybook&token=",
// };

const environment = {
  appVersion: "1.0.0",
  env: "production",
  production: true,
  apiUrl: "https://dev-sb-api.sgrbk.com/",
  authApi: "https://dev-sb-api.sgrbk.com/",
  comApi: "https://tsbcom.sgrbk.com/",
  spiApi: "https://dev-spi.sgrbk.com/",
  referenceUrl: "https://m.sugarbook.com",
  apiAccessToken: "Ezetj4whWp4XvhhkJ7CBtQWa2cEjUMyl",
  redirectUrl:
    "https://m.sugarbook.club/redirect_handler?url=/&from_cta=candybook&token=",
};

export default environment;
