// Assets
import feature1 from "../assets/logo/landing/feature/feature-1.png";
import feature2 from "../assets/logo/landing/feature/feature-2.png";
import feature3 from "../assets/logo/landing/feature/feature-3.png";
import feature4 from "../assets/logo/landing/feature/feature-4.png";

const landing = {
  features: [
    {
      image: feature1,
      header: "Honesty & Transparency",
      description:
        "Successful relationships require honesty and transparency. We stress the importance of communication to keep relationships free of unnecessary spectacles.",
    },
    {
      image: feature2,
      header: "Verified Profiles",
      description:
        "We remain the only dating platform where members can verify their profiles. A Verified Profile blue tick is evident to display a member's intentions.",
    },
    {
      image: feature3,
      header: "Remain Discreet",
      description:
        "Holding our members' privacy is of utmost importance. Our members have full control of permissions to privat ephotos as well as who views their profiles",
    },
    {
      image: feature4,
      header: "Highly Rated for a Reason",
      description:
        "We are proud to be the leading dating platform, not only in Malaysia, but also throughout Asia.",
    },
  ],
};

export default landing;
