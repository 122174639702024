// General
import "./multi-device.scss";
// Assets
import multiDeviceDesktop from "../../../assets/others/stockphotos/multi-device-desktop.png";
import multiDeviceMobile from "../../../assets/others/stockphotos/multi-device-mobile.png";

const MultiDevice = () => {
  return (
    <div id="landing-multi-device-subcomponent">
      <div className="header">Make Dating Effortless Across All Devices</div>
      <img className="desktop-img" src={multiDeviceDesktop} />
      <img className="mobile-img" src={multiDeviceMobile} />
    </div>
  );
};

export default MultiDevice;
