import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// Static Data
import { API_CONFIG_HEADERS } from "../const/api";
// Environment
import environment from "../environment/environment";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl: environment.authApi }),
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: (body) => ({
        url: "v1/auth",
        method: "POST",
        headers: API_CONFIG_HEADERS.SB_API_HEADERS,
        body: body,
      }),
    }),
  }),
});

export const comApi = createApi({
  reducerPath: "comApi",
  baseQuery: fetchBaseQuery({ baseUrl: environment.comApi }),
  endpoints: (builder) => ({
    profileUpdate: builder.mutation({
      query: (body) => ({
        url: "pwa/api/v1/profile/update",
        method: "POST",
        headers: API_CONFIG_HEADERS.COM_HEADERS,
        body: body,
      }),
    }),
  }),
});

export const spiApi = createApi({
  reducerPath: "spiApi",
  baseQuery: fetchBaseQuery({ baseUrl: environment.spiApi }),
  endpoints: (builder) => ({
    getToken: builder.query({
      query: () => ({
        url: "api/v1/token_passthrough/get-token",
        method: "GET",
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
      }),
    }),
  }),
});

export const { useSignupMutation } = authApi;
export const { useProfileUpdateMutation } = comApi;
export const { useGetTokenQuery } = spiApi;
